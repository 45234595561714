<template>
  <v-main class="geolistening" style="margin-top: 150px;">
    <div class="center-content geolistening_content" style="width: 75%; padding-left: 70px;">
      <v-row class="geolistening__topsection">
        <div class="pt-5 col">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-column">
              <div class="d-flex">
                <h1 class="font15pt font-weight-bold mr-2">GEO listening</h1>
                <span></span>
                <v-icon size="16" color="#1da1f2">fab fa-twitter</v-icon>
              </div>
              <span class="leyendColor font10pt">Find out what is trending anywhere in the world</span>
            </div>
            <div class="d-flex position-relative font12pt">
              <v-select :items="overviews" item-text="name" item-value="id" v-model="boards"
                placeholder="Select board...">
              </v-select>
              <!--  <select
                class="geolistening__context__select py-1 pl-2 pr-15"
                @change="getGeoContextData($event)"
              >
                <option :value="undefined">Select board...</option>
                <option
                  v-for="context in this.overviews"
                  :key="context.id"
                  :value="context.id"
                >
                  {{ context.name }}
                </option>
              </select> -->
              <div class="geolistening__drivers__indicator px-3">
                Drivers:
                {{ selectedContextDrivers ? `(${selectedContextDrivers.length})` : "" }}
              </div>
              <div class="d-flex justify-center align-center">
                <div v-ripple @click="openDropdown = !openDropdown" style="border-radius:5px">
                  <v-icon color="#00a5ff" class="pa-1">fas
                    fa-bars</v-icon>
                </div>
              </div>
              <div v-if="openDropdown" class="dropdown d-flex">
                <div v-for="driver in selectedContextDrivers" :key="driver.key" class="nowrap px-2 py-2 dropdown-item">
                  {{ driver.key }} <b>({{ driver.doc_count }})</b>
                </div>
                <span v-if="!selectedContextDrivers.length > 0">No hay drivers</span>
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <!-- <v-row class="geolistening__driversection d-flex justify-center pa-2">
        <v-col md="11" class="d-flex justify-space-between justify-sm-center flex-wrap">
            <div v-for="(item, index) in drivers" :key="index" class=" d-flex justify-center col-lg-2 col-sm-3 ma-0 pa-0">
              <div class="
              geolistening__driversection__item
              overflow-hidden
              d-flex
              justify-start
              mb-3
            " :class="[
              categoryActived == item.value ? 'catActive' : 'catInactive',
            ]" @click="getCategory(item.value)">
              <div class="geolistening__driversection__item--img" :style="{ backgroundImage: `url(${item.image})` }">
              </div>
              <div class="d-flex flex-column">
                <span class="pl-2 pt-2 font12pt font-weight-bold">{{
                  item.name
                }}</span>
                <span class="ml-2" v-if="item.countExist">{{ item.count }} Drivers</span>
              </div>
            </div>
            </div>
        </v-col>
      </v-row> -->
      <v-row>
        <div class="col">
          <h1 class="text-center font15pt titleColor font-weight-bold">
            Choose a Country
          </h1>
        </div>
      </v-row>
      <v-row class="d-flex justify-center geolistening__countriesection">
        <v-col md="4" class="
            geolistening__countriesection__box
            d-flex
            justify-space-between
          ">
          <div class="
              geolistening__countriesection__box__item
              d-flex
              justify-center
              align-center
              pt-2
              pb-2
            " :class="{ active: peruActived }" @click="getCountry('peru')">
            <img src="https://listen-images.s3.us-east-2.amazonaws.com/peru.png" alt="" />
            <span class="pl-3 titleColor font12pt font-weight-bold">Perú</span>
          </div>
          <div class="
              geolistening__countriesection__box__item
              d-flex
              justify-center
              align-center
              pt-2
              pb-2
            " :class="{ active: chileActived }" @click="getCountry('chile')">
            <img src="https://listen-images.s3.us-east-2.amazonaws.com/chile.png" alt="" />
            <span class="pl-3 titleColor font12pt font-weight-bold">Chile</span>
          </div>
        </v-col>
      </v-row>
      <v-row class="geolistening__driversection d-flex justify-center pa-2" v-if="driversListActived">
        <v-col md="11" class="d-flex justify-space-between justify-sm-center flex-wrap">
            <div v-for="(item, index) in drivers" :key="index" class=" d-flex justify-center col-lg-2 col-sm-3 ma-0 pa-0">
              <div class="
              geolistening__driversection__item
              overflow-hidden
              d-flex
              justify-start
              mb-3
            " :class="[
              categoryActived == item.value ? 'catActive' : 'catInactive',
            ]" @click="getCategory(item.value)">
              <div class="geolistening__driversection__item--img" :style="{ backgroundImage: `url(${item.image})` }">
              </div>
              <div class="d-flex flex-column">
                <span class="pl-2 pt-2 font12pt font-weight-bold">{{
                  item.name
                }}</span>
                <span class="ml-2" v-if="item.countExist">{{ item.count }} Drivers</span>
              </div>
            </div>
            </div>
        </v-col>
      </v-row>
      
      <v-row class="white mb-5">
        <div class="col" style="position: relative" v-if="markersTrend">
          <div>
            <v-progress-linear indeterminate class="prog-bar__geomap" v-if="loadingMap"></v-progress-linear>
          </div>
          <Geomap :markersTrend="markersTrend" ref="Geomap" />
        </div>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import { latLng } from "leaflet";
import Geomap from "@/components/geolisten/Geomap.vue";
import moment from "moment";

import { mapActions } from "vuex";

export default {
  name: "GeoListening",
  components: { Geomap },
  data() {
    return {
      tooltip: "text0",
      drivers: [
        // {
        //   name: "Transportation",
        //   value: "transportation",
        //   image:
        //     "https://listen-images.s3.us-east-2.amazonaws.com/Transportation.png",
        // },
        // {
        //   name: "Drinks",
        //   value: "drinks",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/dirnks.png",
        // },
        {
          name: "Tourism",
          value: "tourism",
          image: "https://listen-images.s3.us-east-2.amazonaws.com/hotel.png",
        },
        {
          name: "Retail",
          value: "retail",
          image: "https://listen-images.s3.us-east-2.amazonaws.com/retail.png",
        },
        // {
        //   name: "Gastronomy",
        //   value: "gastronomy",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/food.png",
        // },
        // {
        //   name: "Deco",
        //   value: "deco",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/deco.png",
        // },
        {
          name: "Finance",
          value: "finance",
          image:
            "https://listen-images.s3.us-east-2.amazonaws.com/financial.png",
        },
        // {
        //   name: "Industry",
        //   value: "industry",
        //   image:
        //     "https://listen-images.s3.us-east-2.amazonaws.com/industry.png",
        // },
        // {
        //   name: "Vehicles",
        //   value: "vehicles",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/auto.png",
        // },
        {
          name: "Government",
          value: "government",
          image:
            "https://listen-images.s3.us-east-2.amazonaws.com/government.png",
        },
        {
          name: "Media",
          value: "media",
          image: "https://listen-images.s3.us-east-2.amazonaws.com/media.png",
        },
        {
          name: "Services",
          value: "services",
          image:
            "https://listen-images.s3.us-east-2.amazonaws.com/Entertaimnet.png",
        },
        {
          name: "Sports",
          value: "sports",
          image: "https://listen-images.s3.us-east-2.amazonaws.com/sports.png",
        },
        {
          name: "Health",
          value: "health",
          image: "https://listen-images.s3.us-east-2.amazonaws.com/health.png",
        },
        // {
        //   name: "Beauty",
        //   value: "beauty",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/Beauty.png",
        //   count: 0,
        // },
        {
          name: "Entertainment",
          value: "entertainment",
          image:
            "https://listen-images.s3.us-east-2.amazonaws.com/Entertaimnet.png",
        },
        // {
        //   name: "Fashion",
        //   value: "fashion",
        //   image: "https://listen-images.s3.us-east-2.amazonaws.com/fashion.png",
        // },
        {
          name: "Technology",
          value: "technology",
          image:
            "https://listen-images.s3.us-east-2.amazonaws.com/technology.png",
        },
      ],
      countryData: {},
      peruActived: false,
      chileActived: false,
      categoryActived: "",
      markersTrend: [],
      userContexts: [],
      selectedContextDrivers: [],
      industriesTrendDetail: [],
      openDropdown: false,
      countryId: "2dfa9ecb0179a4e4",
      boards: null,
      loadingMap: false,
      geoDrivers: {},
      driversListActived: false
    };
  },
  async beforeCreate() {
    await this.$store.dispatch(
      "dashboard/overviews",
      sessionStorage.getItem("client_id")
    );

    //this.getContexts();
  },
  created() {
    this.addTitle("Geolistening");
    this.setContextBoard("");
    this.disabledMenuItem(true);
  },
  async mounted() {
    const geoDrivers = this.$store.getters["global/geoDriversTw"];
    if (geoDrivers) {
      return geoDrivers;
    } else {
      await this.$store.dispatch("global/geoDrivers", 'tw');
    }
  },
  methods: {
    /* async getGeoContextData(contextId) {
      const response = await this.getGeoContext(contextId);
      console.log('response', response);
      this.selectedContextDrivers = response.items.keywords;
    }, */
    async getGeoContext(contextId) {
      this.clearAllData();
      this.loadingMap = true;
      const endDate = moment().format("DD-MM-YYYY");
      const startDate = moment().subtract(30, "d").format("DD-MM-YYYY");
      const data = {
        context_id: contextId,
        start: startDate,
        end: endDate,
        type: "tw",
      };

      try {
        const {
          data: { geoContext },
        } = await this.$store.dispatch("geolistening/geoContext", data);
        this.industriesTrendDetail = geoContext;
        this.selectedContextDrivers = geoContext.items.keywords;
        await this.forDetail();
      } catch (e) {
        console.error(e);
        alert('No active drivers in this context.')
      } finally {
        this.loadingMap = false;
      }

      //return geoContextQuery;
    },
    clearAllData() {
      this.$refs.Geomap.cleanData();
      this.categoryActived = "";
    },
    getCountry(country) {
      this.clearAllData();
      this.countryData = {
        country_id: this.geoDrivers[country].place_id,
        categories: this.geoDrivers[country].categories,
      };
      this.drivers.map((item) => {
        item.countExist = true;
        item.count = this.countryData.categories[item.value]
          ? this.countryData.categories[item.value].length
          : 0;
      });
      if (country === "peru") {
        this.peruActived = true;
        this.chileActived = false;
        this.countryId = "2dfa9ecb0179a4e4";
        //console.log(this.drivers);
      } else {
        this.chileActived = true;
        this.peruActived = false;
        this.countryId = "47a3cf27863714de";
      }
      this.markersTrend = [];
      this.driversListActived = true
      //this.getDetail();
    },
    async getCategory(category) {
      this.updateLoadingMap(true);
      //console.log(category)
      this.categoryActived = category;
      await this.getDetail(category);
      this.updateLoadingMap(false);
    },
    async getDetail(category) {
      const endDate = moment().format("DD-MM-YYYY");
      const startDate = moment().subtract(30, "d").format("DD-MM-YYYY");
      const data = {
        industry_name: category,
        country_id: this.countryId,
        start: startDate,
        end: endDate,
      };
      if (
        (this.categoryActived !== "" && this.peruActived) ||
        this.chileActived
      ) {
        const {
          data: { industriesTrends },
        } = await this.$store.dispatch("geolistening/industriesTrends", data);
        //const industriesTrendsQuery = this.industriesTrends;
        this.selectedContextDrivers = industriesTrends.items.keywords;
        this.industriesTrendDetail = industriesTrends;
        this.forDetail();
      }
    },
    async forDetail() {
      const trendsList = [];
      this.industriesTrendDetail.items.data.aggregations.agg_geohash_grid_sample_geocode.buckets.forEach(
        (item, itemKey) => {
          const words = item["agg_terms_sample_words"].buckets;
          const hashtags = item["agg_terms_sample_hashtags.keyword"].buckets;
          const users = item["agg_terms_sample_mentions.keyword"].buckets;

          trendsList.push({
            trendId: item.key,
            key: itemKey,
            geolocation: latLng(
              item.agg_geo_centroid_sample_geocode.location.lat,
              item.agg_geo_centroid_sample_geocode.location.lon
            ),
            word: words.length
              ? {
                key: words[0].key,
                totalFavs: Math.round(words[0].agg_avg_author_favourites_count.value),
                tweets: item.doc_count,
                reach: Math.round(words[0].agg_avg_sample_scope.value),
                totalRts: Math.round(words[0].agg_avg_sample_retweet_count.value),
                posts: words[0]["agg_terms_sample_text.keyword"].buckets,
                histograms:
                  words[0]["agg_date_histogram_sample_created_at"].buckets,
              }
              : null,
            hashtag: hashtags.length
              ? {
                key: hashtags[0].key,
                totalFavs: hashtags[0].agg_avg_author_favourites_count.value,
                tweets: item.doc_count,
                reach: hashtags[0].agg_avg_sample_scope.value,
                totalRts: hashtags[0].agg_avg_sample_retweet_count.value,
                posts: hashtags[0]["agg_terms_sample_text.keyword"].buckets,
                histograms:
                  hashtags[0]["agg_date_histogram_sample_created_at"].buckets,
              }
              : null,
            user: users.length
              ? {
                key: users[0].key,
                totalFavs: users[0].agg_avg_author_favourites_count.value,
                tweets: item.doc_count,
                reach: users[0].agg_avg_sample_scope.value,
                totalRts: users[0].agg_avg_sample_retweet_count.value,
                posts: users[0]["agg_terms_sample_text.keyword"].buckets,
                histograms:
                  users[0]["agg_date_histogram_sample_created_at"].buckets,
              }
              : null,
            sentiment: item["agg_terms_sample_sentiment.keyword"].buckets.length
              ? item["agg_terms_sample_sentiment.keyword"].buckets[0].key
              : null,
          });
        }
      );
      this.markersTrend = trendsList;
    },
    updateLoadingMap(status) {
      this.loadingMap = status;
    },
    ...mapActions("global", [
      "addTitle",
      "setContextBoard",
      "disabledMenuItem",
    ]),
  },
  computed: {
    overviews() {
      return this.$store.getters["dashboard/overviews"];
    },
  },
  watch: {
    boards(contextId) {
      this.getGeoContext(contextId);
    },
    "$store.state.global.geoDriversTw": {
      immediate: true,
      handler: function () {
        const geoDrivers = this.$store.getters["global/geoDriversTw"];
        if (geoDrivers) {
          this.geoDrivers = geoDrivers;
          /* this.loadingMap = false; */
        } else {
          /* this.loadingMap = true; */
        }
      },
    },
  },
};
</script>

<style>
.prog-bar__geomap {
  width: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
}

.catActive {
  background-color: #00a5ff !important;
  color: white !important;
  text-decoration: none !important;
}

.catInactive {
  background-color: white !important;
}

.geolistening__driversection__item {
  width: 173px;
  height: 50px;
  border-radius: 10px 0 0 0;
  cursor: pointer;
  box-shadow: 0 2px 3.9px 0.1px rgb(0 0 0 / 15%);
}

.geolistening__driversection__item.geolistening__driversection__item--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.geolistening__driversection__item:not('.geolistening__driversection__item--disabled'):hover {
  background-color: #00a5ff !important;
}

.geolistening__driversection__item:not('.geolistening__driversection__item--disabled'):hover span {
  color: #fff !important;
}

.geolistening__driversection__item:not('.geolistening__driversection__item--disabled'):hover .geolistening__driversection__item--img {
  transform: scale(1.1);
}

.geolistening__driversection__item--img {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
  transition: transform 0.2s;
}

.geolistening__countriesection__box__item {
  width: 150px;
  opacity: 0.5;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.geolistening__countriesection__box__item:hover {
  opacity: 1;
  border-bottom: 2px solid #00a5ff;
}

.geolistening__countriesection__box__item.active {
  opacity: 1;
  border-bottom: 2px solid #00a5ff;
}

.geolistening__context__select {
  background-color: #fff !important;
  border: 1px solid #c7cfe2 !important;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px 1px rgb(72 72 72 / 18%);
}

.geolistening__drivers__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.dropdown {
  position: absolute;
  top: 0;
  margin-top: 50px;
  right: 0;
  background: #fff;
  padding: 5px 5px;
  box-shadow: 0 7px 17px -5px #a3a3a3;
}

.dropdown-item:hover {
  color: #00b1f2;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
  .geolistening_content {
    width: 90% !important;
  }
}
</style>
