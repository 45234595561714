<template>
  <v-main>
    <v-row>
      <div class="col geoListen-map">
        <div class="geoListen-map__check">
          <input type="radio" v-model="geoItem" value="words" /> Words
          <input type="radio" v-model="geoItem" value="hashtag" /> Hashtag
          <input type="radio" v-model="geoItem" value="user" /> User
        </div>
        <div class="geoListen-map__map">
          <l-map ref="myMap" v-if="showMap" :zoom="zoom" :center="center" style="height: 500px">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker v-for="markerItem in markersTrend_2" :key="markerItem.key" :lat-lng="markerItem.geolocation"
              @click="getDetailFromMarker(markerItem.trendId)">
              <l-icon :icon-anchor="staticAnchor">
                <div :class="
  markerSelected === markerItem.trendId
    ? `textLabelclass2 ${markerItem.sentiment}`
    : `textLabelclass ${markerItem.sentiment}`
" v-if="geoItem === 'hashtag' && markerItem.hashtag">
                  #{{ markerItem.hashtag.key }}
                </div>
                <div :class="
  markerSelected === markerItem.trendId
    ? `textLabelclass2 ${markerItem.sentiment}`
    : `textLabelclass ${markerItem.sentiment}`
" v-if="geoItem === 'user' && markerItem.user">
                  @{{ markerItem.user.key }}
                </div>
                <div :class="
  markerSelected === markerItem.trendId
    ? `textLabelclass2 ${markerItem.sentiment}`
    : `textLabelclass ${markerItem.sentiment}`
" v-if="geoItem === 'words' && markerItem.word">
                  {{ markerItem.word.key }}
                </div>
              </l-icon>
            </l-marker>
          </l-map>
        </div>
        <div class="geoListen-map__metrics d-flex justify-space-between mt-5">
          <div class="
              geoListen-map__metrics__box
              bgColorBase
              d-flex
              flex-column
              align-center
              pt-5
              pb-5
            ">
            <span class="font12pt parColor">Tweets</span>
            <span class="font18pt titleColor">{{ tweets }}</span>
          </div>
          <div class="
              geoListen-map__metrics__box
              bgColorBase
              d-flex
              flex-column
              align-center
              pt-5
              pb-5
            ">
            <span class="font12pt parColor">Reach</span>
            <span class="font18pt titleColor">{{ reach }}</span>
          </div>
          <div class="
              geoListen-map__metrics__box
              bgColorBase
              d-flex
              flex-column
              align-center
              pt-5
              pb-5
            ">
            <span class="font12pt parColor">Total RT's</span>
            <span class="font18pt titleColor">{{ totalRts }}</span>
          </div>
          <div class="
              geoListen-map__metrics__box
              bgColorBase
              d-flex
              flex-column
              align-center
              pt-5
              pb-5
            ">
            <span class="font12pt parColor">Total Fav's</span>
            <span class="font18pt titleColor">{{ totalFavs }}</span>
          </div>
        </div>
        <div class="geoListen-map__postinfo mt-5 pt-5">
          <div class="geoListen-map__postinfo__box col-6 pl-0">
            <div class="geoListen-map__postinfo__textselect mb-3">
              <div class="col pl-0">
                <span class="titleColor font15pt font-weight-bold">Selected:</span>
                <span class="ml-2 linkColor font15pt font-weight-bold">{{
    textSelected
}}</span>
              </div>
            </div>
            <div class="geoListen-map__postinfo__graph d-flex">
              <div class="col-5 pl-0">
                <span class="titleColor font15pt font-weight-bold mb-4 d-block">Local</span>
                <div class="d-flex justify-space-between mb-4">
                  <h2 class="font12pt parColor font-weight-light">Total:</h2>
                  <h2 class="font12pt">
                    <span class="titleColor">{{ local.total }}</span>
                    <span class="ml-4 leyendColor font-weight-light font11pt">{{ local.total }}%</span>
                  </h2>
                </div>
                <div class="d-flex justify-space-between">
                  <h2 class="font12pt parColor font-weight-light">Last day:</h2>
                  <h2 class="font12pt">
                    <span class="titleColor">{{ local.lastDay }}</span>
                    <span class="ml-4 leyendColor font-weight-light font11pt">{{ local.lastDay }}%</span>
                  </h2>
                </div>
              </div>
              <div class="col d-flex flex-column">
                <span class="leyendColor font10pt d-block text-right">Last 30 days</span>

                <apexchart type="area" height="100" :options="chartOptionsSamples" :series="series">
                </apexchart>
              </div>
            </div>
            <div class="col pl-0">
              <span class="
                  titleColor
                  font15pt
                  font-weight-bold
                  mb-4
                  d-block
                  mt-5
                  pt-5
                ">Top</span>
            </div>
            <div class="geoListen-map__postinfo__post d-flex" v-for="(post, index) in posts" :key="index">
              <div class="col-5">
                <a target="_blank" :href="'http://twitter.com/' + post.author">
                  <v-avatar class="bgColorPrincipal d-inline-flex" size="40">
                    <span class="white--text font26pt">@</span>
                  </v-avatar>
                  <span class="ml-3 d-inline-flex">@{{ post.author }}</span>
                </a>
              </div>
              <div class="col">
                <span class="parColor font12pt pt-0 d-inline-block">{{
    post.content
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </v-main>
</template>

<script>
import { latLng } from "leaflet";
import * as moment from "moment/moment";
import { util } from "@isc/styleguide";

export default {
  name: "Geomap",
  props: {
    markersTrend: Array,
  },
  data() {
    return {
      markerSelected: "",
      zoom: 3,
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      center: latLng(-16.1225411, -75.058407), //-16.1225411,-75.058407
      withPopup: latLng(47.41322, -1.219482),
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showMap: true,
      staticAnchor: [16, 37],
      geoItem: "words",
      totalFavs: 0,
      tweets: 0,
      reach: 0,
      totalRts: 0,
      textSelected: "",
      posts: [],
      histograms: [],
      local: {
        total: 0,
        lastDay: 0,
      },
      geoList: [],
      series: [
        {
          name: "samples",
          data: [],
        },
      ],
      chartOptionsSamples: {
        chart: {
          type: "area",
          height: 100,
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#00a5ff",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        legend: {
          horizontalAlign: "left",
        },
      },
    };
  },
  computed: {
    markersTrend_2: function () {
      this.cleanData()
      if (this.geoItem === "words") {
        return this.markersTrend.filter((data) => data.word);
      } else {
        if (this.geoItem === "hashtag") {
          return this.markersTrend.filter((data) => data.hashtag);
        } else {
          return this.markersTrend.filter((data) => data.user);
        }
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //     this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD();
    // });
    // console.log('---->>>', this.markersTrend);
  },
  created() { },
  methods: {
    getDetailFromMarker(trendId) {
      // this.$emit('trendId', trendId);
      const markerSelected = this.markersTrend.find(
        (i) => i.trendId === trendId
      );
      this.markerSelected = markerSelected.trendId;
      let markerDetail = {};
      switch (this.geoItem) {
        case "words":
          this.textSelected = `${markerSelected.word.key}`;
          markerDetail = markerSelected.word;
          break;
        case "hashtag":
          this.textSelected = `#${markerSelected.hashtag.key}`;
          markerDetail = markerSelected.hashtag;
          break;
        case "user":
          this.textSelected = `@${markerSelected.user.key}`;
          markerDetail = markerSelected.user;
          break;
      }
      this.totalRts = Math.round(markerDetail.totalRts);
      this.totalFavs = Math.round(markerDetail.totalFavs);
      this.reach = Math.round(markerDetail.reach);
      this.tweets = Math.round(markerDetail.tweets);
      this.histograms = markerDetail.histograms;

      const postList = [];
      markerDetail.posts.map((i) => {
        postList.push({
          author: i["agg_terms_author_screen_name.keyword"].buckets[0].key,
          content: i.key,
        });
      });

      this.posts = postList;

      this.local.total = markerDetail.tweets;
      this.local.lastDay = this.histograms[0].doc_count;

      let histogramList = {};
      for (let i = 0; i < 30; i++) {
        //console.log('tiempo', moment().subtract(i, 'days').format("DD-MM-YYYY"));
        histogramList[moment().subtract(i, "days").format("DD-MM-YYYY")] = 0;
      }
      histogramList[
        util.convertTimestampToDate(this.histograms[0].key, "DD-MM-YYYY")
      ] = this.histograms[0].doc_count;

      const categories = [];
      const geoList = [];
      Object.keys(histogramList).map((i) => {
        // console.log(i);
        geoList.push(histogramList[i]);
        categories.push(i);
      });
      if (categories.length == geoList.length) {
        const newData = this.mixArrays(categories, geoList);
        //this.chartOptionsSamples.xaxis.categories = categories;
        this.series = [
          {
            name: "samples",
            data: newData,
          }
        ];
      } else {
        this.chartOptionsSamples.xaxis.categories = categories;
        this.series = [
          {
            name: "samples",
            data: geoList,
          },
        ];
      }
    },
    cleanData() {
      this.markerSelected = '';
      this.totalFavs = 0;
      this.tweets = 0;
      this.reach = 0;
      this.totalRts = 0;
      this.textSelected = "";
      this.posts = [];
      this.histograms = [];
      this.local = {
        total: 0,
        lastDay: 0,
      };
      this.series = [];
      //this.geoItem = "words";
    },
    mixArrays(xaxis, categories) {
      const array = [];
      for (const [index, item] of xaxis.entries()) {
        array.push({ y: categories[index], x: item });
      }
      return array.reverse();
    },
  },
};
</script>



<style>
.geoListen-map {
  z-index: 1;
  padding: 20px 50px;
}

.geoListen-map__metrics__box {
  width: 24%;
}

.geoListen-map__postinfo__post:hover {
  background-color: #e6f8ff;
}

.textLabelclass {
  background-color: rgba(55, 175, 239, 0.8);
  padding: 5px 8px;
  display: inline-table !important;
  color: #fff !important;
  white-space: nowrap;
  font-size: 1.3em;
}

.textLabelclass.neu {
  border-left: 8px solid #828a96 !important;
}

.textLabelclass.pos {
  border-left: 8px solid #b0d797 !important;
}

.textLabelclass.neg {
  border-left: 8px solid #ff7e7e !important;
}

.textLabelclass2 {
  background-color: #6b70c3;
  box-shadow: 2px 3px 35px -6px rgba(0, 0, 0, 0.75);
  padding: 5px 8px;
  display: inline-table !important;
  color: #fff !important;
  white-space: nowrap;
  font-size: 1.3em;
}

.textLabelclass2.neu {
  border-left: 8px solid #828a96 !important;
}

.textLabelclass2.pos {
  border-left: 8px solid #b0d797 !important;
}

.textLabelclass2.neg {
  border-left: 8px solid #ff7e7e !important;
}
</style>
